import React from "react";
import logo from "../orange-pages.png";

function MenuBar() {
    return (
      <div className="MenuBar">
        <div className="logo">
            <img src={logo} alt="Orange Pages Logo" />
        </div>
        <div className="filters">
            <button>MEETUPS</button> <button>BUSINESSES (Coming soon!)</button>
        </div>
        <div className="links">
            <a href="https://opadmin.bitcoinmagazine.com/">SIGN IN</a> <a href="https://opadmin.bitcoinmagazine.com/">REGISTER</a>
        </div>
      </div>
    );
  }
  
export default MenuBar;  