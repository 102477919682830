import { React, useState } from "react";
import Modal from './Modal';
import location from "../location.png";

function Listing({ listingData}) {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className='Listing'>
            <div>
                {(listingData["images"] !== null) ? (
                    <img src={listingData["images"][0]["src"]} alt="Meetup Logo" />
                ) : (
                    <img src="https://meetups.wpengine.com/wp-content/uploads/2023/06/meetup.png" alt="Meetup Logo" />
                )}
            </div>
            <div>
                <h3>{listingData["name"]}</h3>
                <p>
                    <img src={location} alt="Location Icon" /> {listingData["locations"][0]["name"]}
                    {typeof(listingData["locations"][1]) !== 'undefined' && (<>, {listingData["locations"][1]["name"]}</>)}
                </p>
            </div>
            <div>
                <button onClick={() => setShowModal(true)}>MORE INFO</button>
            </div>
            {showModal ? <Modal listingData={listingData} setShowModal={setShowModal} /> : null}
        </div>
    )
}

export default Listing;