import './App.css';
import { React, useEffect, useState } from 'react';
import MenuBar from './Components/MenuBar';
import AdCarousel from './Components/AdCarousel';
import Listing from './Components/Listing';

import map from "./meetup-graphic.png";
// import network from './meetup-network.png';

function App() {
  const [allListings, setAllListings] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);

  useEffect(() => {
    fetch('https://opadmin.bitcoinmagazine.com/wp-json/directorist/v1/listings?per_page=100')
    .then(res => res.json())
    .then(r => {
      setAllListings(r);
    })
    .catch(() => { console.log('Failed to load listings')});
  }, []);

  return (
    <div className='App'>
      <MenuBar />
      <div className='intro'>
      </div>
      <div className='search'>
        <div>
          {/* <img src={network} alt="Meetup Network" /> */}
          <h2>FIND YOUR LOCAL BITCOIN MEETUP</h2>
          <input placeholder="New York Bitcoiners" onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} />
          <br />
          <button>+ ADD LISTING</button>
        </div>
        <div>
          <img src={map} alt="Meetup Map" />
        </div>
      </div>
      <AdCarousel />
      <div className='ListingsContainer'>
        {allListings == null ? (
          <p>Could not load listings.</p>
        ) : (
          allListings.map((item, index) => (
            (searchTerm === null || searchTerm === "") && (
              <Listing listingData={item} key={index} />
            )
          ))
        )}
        {allListings == null ? (
          <p>Could not load listings.</p>
        ) : (
          allListings.map((item, index) => (
            (searchTerm !== null && (item.name.toLowerCase().includes(searchTerm) || item.locations.some(i => i.slug.includes(searchTerm)))) && (
              <Listing listingData={item} key={index} />
            )
          ))
        )}
      </div>
    </div>
  );
}

export default App;
