import React from "react";
import location from "../location.png";
import facebook from "../facebook.png";
import twitter from "../twitter.png";
import telegram from "../telegram.png";
import discord from "../discord.png";

function Modal({ listingData, setShowModal }) {
    return (
        <div className="Modal">
            <div className="Banner">
                <span className="closeModal" onClick={() => setShowModal(false)}>
                    x
                </span><br/>
                <h3>{listingData["name"]}</h3>
                <p>
                    <img src={location} alt="Location Icon" /> {listingData["locations"][0]["name"]}
                    {typeof(listingData["locations"][1]) !== 'undefined' && (<>, {listingData["locations"][1]["name"]}</>)}
                </p>
            </div>
            <div className="MoreInformation">
                <div>
                    {(listingData["images"] !== null) ? (
                        <img src={listingData["images"][0]["src"]} alt="Meetup Logo" />
                    ) : (
                        <img src="https://meetups.wpengine.com/wp-content/uploads/2023/06/meetup.png" alt="Meetup Logo" />
                    )}
                    <h4>Description</h4>
                    {listingData["description"].replace(/(<([^>]+)>)/gi, "")}
                </div>
                <div>
                    <h4>Contact</h4>
                    <p>
                        {/* {listingData["email"]} */}
                        {(listingData["social_links"] !== null) && (
                            listingData["social_links"].map((item, index) => (
                                (item.id === "meetup") && (
                                    <>
                                        <a key={index} href={item.url}>{item.url}</a>
                                    </>
                                )
                            ))
                        )}
                    </p>
                    <h4>Social</h4>
                    <p>
                    {(listingData["social_links"] !== null) && (
                        listingData["social_links"].map((item, index) => (
                            <React.Fragment key={index}>
                                {item.id === "twitter" && (
                                    <a href={item.url} target="_blank" rel="noreferrer"><img src={twitter} alt="Twitter Logo" /></a>
                                )}
                                {item.id === "facebook" && (
                                    <a href={item.url} target="_blank" rel="noreferrer"><img src={facebook} alt="Facebook Logo" /></a>
                                )}
                                {item.id === "discord" && (
                                    <a href={item.url} target="_blank" rel="noreferrer"><img src={discord} alt="Discord Logo" /></a>
                                )}
                                {item.id === "telegram" && (
                                    <a href={item.url} target="_blank" rel="noreferrer"><img src={telegram} alt="Telegram Logo" /></a>
                                )}
                            </React.Fragment>
                        ))
                    )}
                    </p>
                    <h4>Is this your meetup?</h4>
                    <p>
                        Claim this meetup to begin managing content for this page!
                    </p>
                    <button onClick={() => window.location.href=listingData["permalink"]}>CLAIM NOW</button>
                </div>
            </div>
        </div>
    )
}

export default Modal;